import React from "react";
import { Formik, Form } from "formik";
import AutoCompleteInput from "./AutoCompleteInput";
import AutoCompletePeriod from "./AutoCompletePeriod";

import TextInputDealFilter from "./TextInputDealFilter";
import * as Yup from "yup";

function FilterForm(props) {
  const onSubmit = (values) => {};
  const validationSchema = { after: Yup.date().min("2010-01-01", "Must be in 2010 or later") };

  return (
    <Formik
      initialValues={undefined}
      validationSchema={Yup.object(validationSchema)}
      // enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        <div className={"flex flex-wrap w-full gap-4 p-4 bg-white rounded-xl" + props.additionalStyle}>
          {props && props.formFields && props.formFields.period && (
            <div className="w-full md:w-auto">
              <AutoCompletePeriod
                className="w-full z-3000"
                label={props.formFields.period.label}
                name={props.formFields.period.name}
                isInactive={props.formFields.period.isInactive}
                value={props.formFields.period.value}
                required={props.formFields.period.required}
                options={props.formFields.period.options}
                onChange={props.formFields.period.onChange}
              />
            </div>
          )}
          <div className="flex  flex-wrap lg:flex-nowrap gap-[16px] w-full md:w-auto">
            <AutoCompleteInput
              label={props.formFields.client.label}
              name={props.formFields.client.name}
              value={props.formFields.client.value}
              required={props.formFields.client.required}
              onChange={props.formFields.client.onChange}
              options={props.formFields.client.options}
              getCustomOptionLabel={props.formFields.client.getCustomOptionLabel}
            />
            {props.formFields.clientTwo && (
              <AutoCompleteInput
                label={props.formFields.clientTwo.label}
                name={props.formFields.clientTwo.name}
                customStyle="client-two"
                value={props.formFields.clientTwo.value}
                required={props.formFields.clientTwo.required}
                onChange={props.formFields.clientTwo.onChange}
                options={props.formFields.clientTwo.options}
                getCustomOptionLabel={props.formFields.clientTwo.getCustomOptionLabel}
              />
            )}
          </div>
          <div className="w-full md:w-auto">
            <AutoCompleteInput
              label={props.formFields.broker.label}
              name={props.formFields.broker.name}
              value={props.formFields.broker.value}
              required={props.formFields.broker.required}
              onChange={props.formFields.broker.onChange}
              options={props.formFields.broker.options}
              getCustomOptionLabel={props.formFields.broker.getCustomOptionLabel}
            />
          </div>
          <div className="w-full md:w-auto">
            <AutoCompleteInput
              label={props.formFields.product.label}
              name={props.formFields.product.name}
              value={props.formFields.product.value}
              required={props.formFields.product.required}
              onChange={props.formFields.product.onChange}
              options={props.formFields.product.options}
              getCustomOptionLabel={props.formFields.product.getCustomOptionLabel}
            />
          </div>
          <div className="w-full md:w-auto deal-filter">
            <TextInputDealFilter
              label={props.formFields.olyxRef.label}
              name={props.formFields.olyxRef.name}
              type={props.formFields.olyxRef.fieldType}
              value={props.formFields.olyxRef.value}
              required={props.formFields.olyxRef.required}
              onChange={props.formFields.olyxRef.onChange}
            />
          </div>
          <div className="w-full md:w-auto">
            <AutoCompleteInput
              label={props.formFields.dealInvoiceStatus.label}
              name={props.formFields.dealInvoiceStatus.name}
              value={props.formFields.dealInvoiceStatus.value}
              required={props.formFields.dealInvoiceStatus.required}
              onChange={props.formFields.dealInvoiceStatus.onChange}
              options={props.formFields.dealInvoiceStatus.options}
              getCustomOptionLabel={props.formFields.dealInvoiceStatus.getCustomOptionLabel}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
}

export default FilterForm;
