import FormGenerator from "../FormGenerator/FormGenerator";
import CheckboxFilter from "../../interfaces/CheckboxFilter";
import { CircularProgress } from "@material-ui/core";
import { FormEvent, useState } from "react";
import { FormField } from "../../interfaces/FormGeneratorProps";
import CreateOrUpdateClientFormProps from "../../interfaces/UpdateClientFormProps";
import useCreateProduct from "../../typedHooks/mutations/useCreateProduct";
import useUpdateProduct from "../../typedHooks/mutations/useUpdateProduct";
import useProductFormOptions from "../../typedHooks/queries/queryProductFormOptions";
import { toast } from "react-toastify";
import { Category } from "../../codegen/gql/graphql";

const CreateOrUpdateProductForm = ({ initialValues, closeAfterSubmit }: CreateOrUpdateClientFormProps) => {
  const productExists = initialValues ? true : false;
  const { dataFromQuery: productFormOptions } = useProductFormOptions({});
  const createProduct = useCreateProduct();
  const updateProduct = useUpdateProduct();

  const [productName, setProductName] = useState<string | undefined>(undefined);
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const [productIsActive, setProductIsActive] = useState<boolean>(true);
  const checkBoxOptions: CheckboxFilter[] = [{ label: "This product is active", value: "productIsActive", checked: productIsActive }];

  const formFields: FormField[] = [
    {
      label: `Product info: ${productName ?? "New product"}`,
      order: "order-first",
      type: "heading",
    },
    {
      initialValue: productName,
      isRequired: true,
      label: "Product name*",
      onChange: (name: string) => {
        setProductName(name ?? "");
      },
      order: "order-1",
      placeholder: "",
      showDefaultValue: false,
      type: "text",
    },
    {
      initialValue: categoryId,
      isRequired: true,
      label: "Category",
      onChange: (providedCategoryGroupId: string) => {
        setCategoryId(providedCategoryGroupId && providedCategoryGroupId !== "0" ? providedCategoryGroupId : undefined);
      },
      options: productFormOptions?.categories
        ? productFormOptions?.categories.map((clientGroup: Category) => ({ id: String(clientGroup.id), name: clientGroup.name }))
        : [],
      order: "order-1",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      type: "checkboxes",
      checkboxInitialValue: checkBoxOptions,
      label: "",
      order: "order-1",
      onChange: (value: string) => {
        if (value === "productIsActive") {
          setProductIsActive(!productIsActive);
        }
      },
      // to match options interface
      checkboxOptions: checkBoxOptions,
      additionalStyles: "w-full border-none py-0",
    },
    {
      label: "Submit product info",
      order: "order-3",
      type: "submit",
    },
  ];

  const mutationKeys = (categoryId: string | undefined, id: string | undefined, productIsActive: boolean, productName: string | undefined) => {
    return {
      input: {
        name: productName,
        categoryId: Number(categoryId),
        id: id ? Number(id) : undefined,
        isActive: productIsActive,
      },
    };
  };

  const onSubmitFromParent = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (productExists) {
        await updateProduct({ variables: mutationKeys(categoryId, initialValues?.id, productIsActive, productName) });
        toast.success(`Info for ${productName} has been updated`);
      } else {
        await createProduct({ variables: mutationKeys(categoryId, undefined, productIsActive, productName) });
        toast.success(`${productName} has been created`);
      }
      if (closeAfterSubmit) {
        closeAfterSubmit();
      }
    } catch (error) {
      toast.error(`Something went wrong in submitting information for ${productName}`);
    }
  };

  if (productFormOptions) {
    return (
      <FormGenerator
        closeFormFunction={closeAfterSubmit}
        customFormStyles={{
          parent:
            "h-full  w-full pb-[80px] border-box flex flex-wrap gap-y-8 bg-white p-5 rounded-xl justify-start md:justify-between lg:bp-[40px] lg:justify-between lg:p-[40px] lg:pb-[40px]",
          children: "w-[100%] md:w-[48.5%]",
        }}
        formFields={formFields}
        layoutOptions={{ hasLabelsOnTop: true, hasCloseButton: true }}
        onSubmitFromParent={onSubmitFromParent}
      />
    );
  }
  return (
    <div className="w-fit h-[120px] bg-white rounded-lg flex flex-wrap justify-center items-center gap-4 p-5">
      <span className="text-center">Loading product data...</span>
      <CircularProgress />
    </div>
  );
};

export default CreateOrUpdateProductForm;
