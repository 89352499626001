import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssTextField from "../FormFields/CssTextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "364E66",
    height: "40px",
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
}));

function TextInputDealFilter(props) {
  const classes = useStyles();
  const textInput = useRef(null);

  const defaultLabel = props.value === "0" || props.value === null ? "All" : props.value;

  return (
    <>
      <CssTextField
        className={`${props.value === "" || props.value === null ? "empty-text" : "full-text"} text-filter w-full md:max-w-[160px]`}
        ref={textInput}
        classes={classes}
        disableToolbar
        variant="outlined"
        fullWidth
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        InputProps={{
          startAdornment: (
            <div
              onClick={() => {
                textInput.current.firstChild.childNodes[1].focus();
              }}
              className="whitespace-nowrap"
            >
              <SearchIcon className="w-2 h-2 ml-2 mr-1" />
              <span className="w-auto mr-2 placeholder">Olyxref:</span>
            </div>
          ),
          endAdornment: (
            <div className="cursor-pointer clear-icon">
              <CloseIcon className="mt-0.5" onClick={() => props.onChange("")}></CloseIcon>
            </div>
          ),
        }}
      />
    </>
  );
}

export default TextInputDealFilter;

//props.onChange("")
