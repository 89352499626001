import { gql } from "@apollo/client";

const GET_CLIENTS_FULL = gql`
  query getClients {
    clients {
      id
      companyName
      firstDealDate
      companyNameExact
      idExact
      broker {
        brokerName
        id
      }
      country {
        name
        id
      }
      clientGroup {
        clientGroupName
        id
      }
    }
  }
`;

export default GET_CLIENTS_FULL;
