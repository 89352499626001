import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const CREATE_PRODUCT = gql`
  mutation ProductUpdate($input: ProductUpdateInput!) {
    productupdate(input: $input) {
      ... on Product {
        id
        name
        isActive
        category {
          id
          name
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useUpdateProduct = () => {
  const [useUpdateProduct] = useMutation(CREATE_PRODUCT, { refetchQueries: ["productFormOptions", "getDealOptions"] });

  return useUpdateProduct;
};

export default useUpdateProduct;
