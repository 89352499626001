import { useState } from "react";
import helpers from "../../services/helpers";
import { motion, AnimatePresence } from "framer-motion";

export const LegendItem = ({ data, location, hasNegativeValue }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="relative">
      <AnimatePresence>
        {isHovered && (
          <motion.div
            className="absolute bottom-11 bg-black/75 p-2 rounded-lg	text-white left-1/2 transform -translate-x-1/2 z-40  w-48"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <span className="block text-xs capitalize whitespace-nowrap text-center">{data.label}</span>
            {data?.data.map((d, i) => (
              <div
                key={d.id}
                className={` w-11/12 mx-auto text-xs ${
                  !hasNegativeValue && (data.label === "paid" || data.label === "future") ? "grid-cols-2 grid gap-2" : "text-center"
                }`}
              >
                {!hasNegativeValue && (data.label === "paid" || data.label === "future") && <span>{d.label}</span>}
                <span>{helpers.formatMoney(d.expectedRevenue, "EUR")}</span>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <div
        key={data.label}
        className={`flex gap-2 justify-center items-center py-0.5 px-2 rounded-lg transition-all ${
          data.label !== "future" && location === "myDeals" ? "hover:bg-[#d8edf4] cursor-pointer" : ""
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex justify-center items-center gap-2">
          <div
            className={`w-[9px] h-[9px] rounded-sm ${
              data.label === "future" ? "border border-gray-light" : data.label === "?" ? "border border-[#9ba3ae]" : ""
            } `}
            style={{ backgroundColor: data.color }}
          ></div>
          <div className="text-sm capitalize text-[#86888D]">{data.label}</div>
        </div>
      </div>
    </div>
  );
};

export default LegendItem;
