import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import BarplotRects from "./BarplotRects";
import PaymentPerformanceLegend from "./PaymentPerformanceLegend";
import BarplotTodayLine from "./BarplotTodayLine";
import BarplotTooltip from "./BarplotTooltip";
import paymentPerformanceDataFormatting from "../../services/helperFunctions/paymentPerformanceDataFormatting";
import calculatePaymentPerformanceHasNegativeValue from "../../services/helperFunctions/calculatePaymentPerformanceHasNegativeValue";
import PaymentPerformanceClientlist from "./PaymentPerformanceClientlist";
import { AnimatePresence, motion } from "framer-motion";
import useComponentVisible from "../../typedHooks/useComponentVisible";

export const Barplot = ({ location, data, loading }) => {
  const refWrapper = useRef();

  const today = new Date();
  const thisYear = today.getFullYear().toString();

  const [width, setWidth] = useState(refWrapper && refWrapper.current ? refWrapper.current.clientWidth : null);
  const [containerPosition, setContainerPosition] = useState({ x: 0 });
  const [isHovered, setIsHovered] = useState({});
  const [isClicked, setIsClicked] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, offset: 0 });
  const [clientListPosition, setClientListPosition] = useState({ x: 0, offset: 0 });
  const [clickLocation, setClickLocation] = useState("");

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const hasNegativeValue = calculatePaymentPerformanceHasNegativeValue(data);
  const { allPayments, paidEur } = paymentPerformanceDataFormatting(hasNegativeValue, data, thisYear);

  useEffect(() => {
    if (refWrapper.current) {
      setContainerPosition(refWrapper.current.getBoundingClientRect().x);
    }
  }, []);

  const handleMouseEnter = (e) => {
    setIsHovered(e);
  };

  const handleMouseLeave = () => {
    setIsHovered({});
  };

  const handleOnclick = (e) => {
    setClientListPosition({ x: e.clientX, offset: e.offsetX });
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      setTooltipPosition({ x: e.clientX, offset: e.offsetX });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const updateDimensions = () => {
    if (refWrapper && refWrapper.current) {
      setWidth(refWrapper.current.clientWidth);
      setContainerPosition(refWrapper.current.getBoundingClientRect().x);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setWidth(refWrapper.current.clientWidth);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  // check if data is loaded
  const isData = allPayments.some((data) => data.expectedRevenue > 0);

  // d3 function where data gets grouped into objects (and cleaned up) and also data being calculated to get a scale for the bars
  const groupData = (data, total) => {
    // use scale to get percent expectedRevenues
    const percent = d3
      .scaleLinear()
      .domain([0, total])
      .range([0, 100]);
    // filter out data that has zero expectedRevenues
    // also get mapping for next placement
    // (save having to format data for d3 stack)
    let cumulative = 0;
    const _data = allPayments
      .map((d) => {
        cumulative += d.expectedRevenue;
        return {
          expectedRevenue: d.expectedRevenue,
          // want the cumulative to prior expectedRevenue (start of rect)
          cumulative: cumulative - d.expectedRevenue,
          label: d.label,
          clients: d.clients,
          percent: percent(d.expectedRevenue),
          id: d.id,
          type: d.type,
          color: d.color ?? undefined,
          group: d.type === "past" ? "pastGroup" : d.type === "thisyear" || (d.label === thisYear && d.type === "future") ? "thisyearGroup" : "futureGroup",
        };
      })
      // Only show expectedRevenue above 0
      .filter((d) => d.expectedRevenue > 0);
    return _data;
  };

  const barHeight = 60;
  const total = d3.sum(allPayments, (d) => d.expectedRevenue);
  const _data = groupData(data, total);

  // X axis
  const xScale = d3
    .scaleLinear()
    .domain([0, total])
    .range([0, width]);

  const todayGrouped = _data.filter((d) => d.type === "thisyear" || d.type === "past");
  const paidGrouped = _data.filter((d) => d.color === "#22B0FF");
  const lastOfTypeToday = todayGrouped && todayGrouped.length > 0 ? todayGrouped.at(-1) : { label: "" };
  const lastOfTypePaid = paidGrouped && paidGrouped.length > 0 ? paidGrouped.at(-1) : { label: "" };

  return (
    <div className="relative w-full mx-auto block bg-white rounded-xl py-4 pt-16 pb-4 mt-4" ref={refWrapper}>
      {!loading && isData && (
        <>
          <div className=" mx-auto h-[60px] cursor-pointer " style={{ width: width + "px" }}>
            {_data.map(
              (d) =>
                isHovered.label === d.label &&
                isHovered.type === d.type && (
                  <BarplotTooltip key={d.id} data={d} tooltipPosition={tooltipPosition} containerPosition={containerPosition}></BarplotTooltip>
                )
            )}
            <div
              className={`max-w-fit	graph-container mx-auto h-[60px] border-[1px] border-[#D9D9D9] rounded-lg overflow-hidden flex ${
                loading && !isData ? "animate-pulse bg-slate-700" : ""
              }`}
            >
              <BarplotTodayLine data={lastOfTypeToday} xScale={xScale}></BarplotTodayLine>
              {_data.map((d) => (
                <div
                  key={d.id}
                  className="relative"
                  onMouseEnter={(e) => {
                    handleMouseEnter({ label: d.label, type: d.type, cumulative: d.cumulative, expectedRevenue: d.expectedRevenue, group: d.group });
                  }}
                  onMouseLeave={handleMouseLeave}
                  onClick={(e) => {
                    setIsClicked(d);
                    handleOnclick(e);
                    setClickLocation("bar");
                    setIsComponentVisible(true);
                  }} // Add onClick event handler here
                >
                  <BarplotRects data={d} barHeight={barHeight} xScale={xScale} isHovered={isHovered} thisYear={thisYear} />
                </div>
              ))}
            </div>
            <AnimatePresence>
              {[..._data, paidEur].map(
                (d, i) =>
                  isComponentVisible &&
                  location === "myDeals" &&
                  d.label === isClicked.label &&
                  d.group === isClicked.group &&
                  d.type === isClicked.type && (
                    <motion.div key={d + i} ref={ref}>
                      <PaymentPerformanceClientlist
                        data={d}
                        clientListPosition={clientListPosition}
                        containerPosition={containerPosition}
                        barWidth={width}
                        clickLocation={clickLocation}
                        xScale={xScale}
                        lastOfTypePaid={lastOfTypePaid}
                      ></PaymentPerformanceClientlist>
                    </motion.div>
                  )
              )}
            </AnimatePresence>
          </div>
          <div
            onClick={() => {
              setClickLocation("legend");
              setIsComponentVisible(true);
            }}
          >
            <PaymentPerformanceLegend
              data={_data}
              paidEur={paidEur}
              location={location}
              setIsClicked={setIsClicked}
              thisYear={thisYear}
              hasNegativeValue={hasNegativeValue}
            ></PaymentPerformanceLegend>
          </div>
        </>
      )}
      {loading && !isData && (
        <div
          className={` mt-4 graph-container  mx-12 h-[62px] border-[0.2px] border-[#D9D9D9] rounded-lg overflow-hidden block animate-pulse bg-slate-300
            }`}
        ></div>
      )}
    </div>
  );
};
