import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const CREATE_PRODUCT = gql`
  mutation Product($input: ProductCreateInput!) {
    productcreate(input: $input) {
      ... on Product {
        id
        name
        isActive
        category {
          id
          name
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useCreateProduct = () => {
  const [useCreateProduct] = useMutation(CREATE_PRODUCT, { refetchQueries: ["productFormOptions", "getDealOptions"] });

  return useCreateProduct;
};

export default useCreateProduct;
