import Button from "@material-ui/core/Button";
import AddIcon from "@mui/icons-material/Add";
import { endOfYear, format, isValid, startOfYear } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import FormGenerator from "../FormGenerator/FormGenerator";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import DealCard from "../../components/DealCard";
import { auth } from "../../firebase";
import useBrokers from "../../hooks/useBrokers";
import useClients from "../../hooks/useClients";
import useDeals from "../../hooks/useDeals";
import useDealStatistics from "../../hooks/useDealStatistics";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useInvoices from "../../hooks/useInvoices";
import usePaymentPerformanceInEur from "../../hooks/usePaymentPerformanceInEur";
import useProduct from "../../hooks/useProduct";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AddDealPreliminaryInfo from "../AddFormDeal/AddDealPreliminaryInfo";
import AnalyticsBar from "../AnalyticsBar/";
import FilterForm from "../FilterForm/FilterForm";
import FilterLabels from "../FilterLabel/FilterLabel";
import MobileFilters from "../MobileFilterForm/MobileFilterForm";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import { Barplot } from "../PaymentPerformanceGraph/Barplot";
import helpersMyDeals from "./helpersMyDeals";
import "./MyDeals.css";

function MyDeals() {
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const [allBrokers, refetchBrokers, networkStatusBrokers] = useBrokers();
  const [broker, setBroker] = useState(queryParams.broker === undefined ? "0" : queryParams.broker);
  const [allClients, refetchClients, networkStatusClients] = useClients();
  const [client, setClient] = useState(queryParams.client === undefined ? "0" : queryParams.client);
  const [clientTwo, setClientTwo] = useState(queryParams.clientTwo === undefined ? "0" : queryParams.clientTwo);
  const [allProducts, refetchProducts] = useProduct();
  const [product, setProduct] = useState(queryParams.product === undefined ? "0" : queryParams.product);
  const [delayOlyxRef, setDelayOlyxRef] = useState(queryParams.olyxRef === undefined ? "" : queryParams.olyxRef);
  const [olyxRef, setOlyxRef] = useState(queryParams.olyxRef === undefined ? "" : queryParams.olyxRef);
  const [dealInvoiceStatus, setDealInvoiceStatus] = useState(queryParams.dealInvoiceStatus === undefined ? "0" : queryParams.dealInvoiceStatus);
  const [hasOpenDealNote, setHasOpenDealNote] = useState(false);
  const [mobileFiltersOn, setMobileFiltersOn] = useState(false);
  const [isPreliminaryFormVisible, setIsPreliminaryFormVisible] = useState(false);
  const [dateIsChangedWithDateRangePicker, setDateIsChangedWithDateRangePicker] = useState(false);

  const dealInvoiceStatusOptions = helpersMyDeals.dealInvoiceStatusOptions;

  const periods = helpersMyDeals.periods;
  const findPeriodOption = helpersMyDeals.findPeriodOption;

  const [period, setPeriod] = useState(
    findPeriodOption(queryParams.dateAfter, queryParams.dateBefore, periods) ?? periods.find((period) => period.label === "2020-Today")
  );

  const [dateAfter, setDateAfter] = useState(
    queryParams.dateAfter && isValid(new Date(queryParams.dateAfter)) ? queryParams.dateAfter : period ? period.dateAfter : null
  );
  const [dateBefore, setDateBefore] = useState(
    queryParams.dateBefore && isValid(new Date(queryParams.dateBefore)) ? queryParams.dateBefore : period ? period.dateBefore : null
  );

  const newQueries = {
    client: client,
    clientTwo: clientTwo,
    broker: broker,
    product: product,
    olyxRef: olyxRef,
    dateAfter: dateAfter,
    dateBefore: dateBefore,
    dealInvoiceStatus: dealInvoiceStatus,
  };
  history.push({ search: queryString.stringify(newQueries) });

  const clientExists = client !== "0" && client !== null;
  const clientTwoExists = clientTwo !== "0" && clientTwo !== undefined && clientTwo !== null;

  const queryDealsInput = {
    brokerIds: broker === "0" || broker === null ? [] : [broker],
    clientIds: client === "0" || client === null ? [] : clientTwoExists ? [client, clientTwo] : [client],
    productIds: product === "0" || product === null ? [] : [product],
    olyxRefs: olyxRef !== "" ? [olyxRef] : undefined,
    after: dateAfter,
    before: dateBefore,
    dealInvoiceStatus:
      dealInvoiceStatus === "0" || !dealInvoiceStatus || !dealInvoiceStatusOptions.map((option) => option.id).includes(dealInvoiceStatus)
        ? undefined
        : dealInvoiceStatusOptions.find((el) => el.id === dealInvoiceStatus).value,
    hasOpenDealNote: hasOpenDealNote,
  };

  const queryPaymentPerformanceInput = {
    clientIds: client === "0" || client === null ? undefined : clientTwoExists ? [client, clientTwo] : [client],
    brokerIds: broker === "0" || broker === null ? undefined : [broker],
    dealSearch: {
      ...queryDealsInput,
    },
  };

  // TODO: backend needs to return the data of intersecting clients instead of all deals the clients
  // in the array have been involved in whnever two clientId's are passed
  const { dealStatistics, loading } = useDealStatistics(queryDealsInput ?? undefined);
  const { paymentPerformanceInEur } = usePaymentPerformanceInEur(queryPaymentPerformanceInput ?? undefined);

  const currentUser = auth ? auth.currentUser : false;
  const [adminClaim, setAdminClaim] = useState(false);
  const [adminTurnedOff, setAdminTurnedOff] = useState(false);
  const hasAdminView = adminClaim && !adminTurnedOff;
  const ref = useRef();
  const isOutOfScreen = useIntersectionObserver(ref);
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (process.env.REACT_APP_GRAPHQL_LINK === "http://localhost:4000/") {
      setAdminClaim(true);
    } else if (currentUser) {
      const idTokenResult = currentUser.getIdTokenResult().then((idTokenResult) => {
        setAdminClaim(idTokenResult.claims.admin ?? false);
      });
    }
  }, [currentUser]);

  const { deals, navigateForward, navigateBack } = useDeals(queryDealsInput ?? undefined);

  const { invoices } = useInvoices({ take: 1 });
  const lastSyncInvoices = invoices && invoices.length > 0 && invoices[0] && "updatedAt" in invoices[0] ? new Date(invoices[0].updatedAt) : false;

  const onChangeBrokerFunction = (event, value) => {
    setBroker(value);
  };
  const onChangeClientFunction = (event, value) => {
    setClient(value);

    if (value === null) {
      setClientTwo(null);
    }
  };
  const onChangeClientTwoFunction = (event, value) => {
    setClientTwo(value);
  };
  const onChangeProductFunction = (event, value) => {
    setProduct(value);
  };
  const onChangeDealInvoiceStatusFunction = (event, value) => {
    setDealInvoiceStatus(value);
  };
  const setClientAndClientTwo = (value) => {
    setClient(value);
    setClientTwo(value);
  };
  const onChangePeriodFunction = (event, value) => {
    if (value === null) {
      setPeriod(periods.find((period) => period.label === "All"));
      setDateAfter(format(startOfYear(new Date(2014, 1, 2)), "yyyy-MM-dd"));
      setDateBefore(format(endOfYear(new Date()), "yyyy-MM-dd"));
    } else {
      if (isValid(new Date(value.dateAfter)) && isValid(new Date(value.dateBefore))) {
        setPeriod(value);
        setDateAfter(value.dateAfter);
        setDateBefore(value.dateBefore);
      }
    }
    setDateIsChangedWithDateRangePicker(false);
  };

  useEffect(() => {
    if (period.label === "2020-Today" && (broker > 0 || olyxRef > 0 || client > 0 || product > 0)) {
      setPeriod(periods.find((period) => period.label === "All"));
      setDateAfter(format(startOfYear(new Date(2014, 1, 2)), "yyyy-MM-dd"));
      setDateBefore(format(endOfYear(new Date()), "yyyy-MM-dd"));
    }
  }, [broker, client, olyxRef, product]);

  useEffect(() => {
    if (delayOlyxRef !== olyxRef) {
      const delayDebounceFn = setTimeout(() => {
        setOlyxRef(delayOlyxRef);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [olyxRef, delayOlyxRef]);

  const onOutsideBarClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setMobileFiltersOn(false);
    }
  };

  const formFields = {
    broker: {
      label:
        allBrokers && allBrokers.brokers && broker !== "0" && broker !== null && allBrokers.brokers.find((item) => item.id === broker)
          ? allBrokers.brokers.find((item) => item.id === broker).brokerName
          : "Broker",
      name: "broker",
      defaultValue: "0",
      value: broker,
      required: false,
      onChange: onChangeBrokerFunction,
      options:
        allBrokers && allBrokers.brokers && allBrokers.brokers.length > 0
          ? allBrokers.brokers
          : [
              { id: 0, brokerName: "name0" },
              { id: 1, brokerName: "name1" },
            ],
      getCustomOptionLabel: (option, options) => {
        const optionObject = options.filter((propsOption) => propsOption.id === option)[0];
        return optionObject && optionObject.brokerName ? optionObject.brokerName : "";
      },
    },
    client: {
      label:
        allClients && allClients.clients && client !== "0" && client !== null && allClients.clients.find((item) => item.id === client)
          ? allClients.clients.find((item) => item.id === client).companyName
          : "Client",
      name: "client",
      defaultValue: "0",
      value: client,
      required: false,
      onChange: onChangeClientFunction,
      options:
        allClients && allClients.clients && allClients.clients.length > 0
          ? allClients.clients
          : [
              { id: 0, companyName: "name0" },
              { id: 1, companyName: "name1" },
            ],
      getCustomOptionLabel: (option, options) => {
        const optionObject = options.filter((propsOption) => propsOption.id === option)[0];
        return optionObject && optionObject.companyName ? optionObject.companyName : "";
      },
    },
    product: {
      label:
        allProducts && allProducts.products && product !== "0" && product !== null && allProducts.products.find((item) => item.id === product)
          ? allProducts.products.find((item) => item.id === product).name
          : "Product",
      name: "product",
      defaultValue: "0",
      value: product,
      required: false,
      onChange: onChangeProductFunction,
      options:
        allProducts && allProducts.products && allProducts.products.length > 0
          ? allProducts.products.filter((product) => ("isActive" in product ? product.isActive : true))
          : [
              { id: 0, name: "name0" },
              { id: 1, name: "name1" },
            ],
      getCustomOptionLabel: (option, options) => {
        const optionObject = options.filter((propsOption) => propsOption.id === option)[0];
        return optionObject && optionObject.name ? optionObject.name : "";
      },
    },
    olyxRef: {
      label: "OLYX Ref",
      name: "olyxRef",
      defaultValue: "",
      value: delayOlyxRef,
      onChange: (event) => {
        if (event === "") {
          setDelayOlyxRef("");
        } else {
          setDelayOlyxRef(event.target.value);
        }
      },
    },
    period: {
      // label: period ? period.find((item) => item.label) : "",
      name: "Deal date",
      isInactive: dateIsChangedWithDateRangePicker,
      options: periods,
      value: period,
      required: false,
      onChange: onChangePeriodFunction,
    },
    dealInvoiceStatus: {
      label: dealInvoiceStatusOptions.find((el) => el.id === dealInvoiceStatus) ? dealInvoiceStatusOptions.find((el) => el.id === dealInvoiceStatus).label : "",
      name: "Invoice Status",
      value: dealInvoiceStatus,
      required: false,
      onChange: onChangeDealInvoiceStatusFunction,
      options: [...dealInvoiceStatusOptions].filter((option) => hasAdminView || !option.adminOnly),
      getCustomOptionLabel: (option, options) => {
        const optionObject = options.filter((propsOption) => propsOption.id === option)[0];
        return optionObject && optionObject.label ? optionObject.label : "";
      },
    },
    hasOpenDealNote: {
      label: "HasOpenDealNote",
      name: "hasOpenDealNote",
      defaultValue: false,
      value: hasOpenDealNote,
      required: false,
      onChange: (event, value) => {
        setHasOpenDealNote(event.target.checked);
      },
    },
  };

  const dateAfterAsDate = format(new Date(dateAfter), "yyyy-MM-dd");
  const dateBeforeAsDate = format(new Date(dateBefore), "yyyy-MM-dd");

  const formFieldsFromToDates = [
    {
      initialValue: dateAfter ? dateAfterAsDate : undefined,
      isRequired: dateIsChangedWithDateRangePicker,
      label: "From",
      newDateCantBeAfter: dateBefore ? dateBeforeAsDate : undefined,
      dateIsNotAllowedMessage: "To date <",
      onChange: (before) => {
        setDateAfter(before ? before : format(startOfYear(new Date(2014, 1, 2)), "yyyy-MM-dd"));
        setDateIsChangedWithDateRangePicker(true);
      },
      order: "order-1",
      showDefaultValue: dateAfter ? false : true,
      type: "date",
    },
    {
      initialValue: dateBefore ? dateBeforeAsDate : undefined,
      isRequired: dateIsChangedWithDateRangePicker,
      label: "To",
      newDateCantBeBefore: dateAfter ? dateAfterAsDate : undefined,
      dateIsNotAllowedMessage: "> From date",
      onChange: (after) => {
        setDateBefore(after ? after : format(endOfYear(new Date()), "yyyy-MM-dd"));
        setDateIsChangedWithDateRangePicker(true);
      },
      order: "order-1",
      showDefaultValue: dateBefore ? false : true,
      type: "date",
    },
  ];

  if (clientExists) {
    formFields.clientTwo = {
      label:
        allClients && allClients.clients && clientTwo !== "0" && clientTwo !== null && allClients.clients.find((item) => item.id === clientTwo)
          ? allClients.clients.find((item) => item.id === clientTwo).companyName
          : "Client",
      name: "and",
      defaultValue: "0",
      value: clientTwo,
      required: false,
      onChange: onChangeClientTwoFunction,
      options:
        allClients && allClients.clients && allClients.clients.length > 0
          ? allClients.clients
          : [
              { id: 0, companyName: "name0" },
              { id: 1, companyName: "name1" },
            ],
      getCustomOptionLabel: (option, options) => {
        const optionObject = options.filter((propsOption) => propsOption.id === option)[0];
        return optionObject && optionObject.companyName ? optionObject.companyName : "";
      },
    };
  }
  return (
    <>
      {isOutOfScreen && !isMobile && (
        <div className="w-full z-50 fixed top-0">
          <FilterForm
            additionalStyle={"rounded-none shadow-xl pl-10 transition duration-3000 ease-in-out"}
            formFields={formFields}
            className={{
              parent: "flex flex-wrap gap-4 bg-white p-3 lg:p-5 pt-10 m-2 rounded-xl justify-between",
              children: "w-[100%] md:w-[48%] lg:w-[23.5%] mb-4",
            }}
            adminClaim={hasAdminView}
          />
        </div>
      )}
      {isMobile && (
        <>
          <MobileFilters
            isOpen={mobileFiltersOn}
            onClick={() => setMobileFiltersOn(!mobileFiltersOn)}
            formFields={formFields}
            formFieldsFromToDate={formFieldsFromToDates}
            dateChangedWithDatePicker={dateIsChangedWithDateRangePicker}
            adminClaim={hasAdminView}
          />
          {mobileFiltersOn && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: "linear",
              }}
              className="fixed h-full w-screen top-0 left-0 z-[49] bg-black/20"
              onClick={(e) => {
                onOutsideBarClick(e);
              }}
            ></motion.div>
          )}
        </>
      )}

      <div className="root">
        {adminClaim && (
          <div>
            <div>Admin view is currently {adminTurnedOff ? "off" : "on"}</div>
            <Button type="submit" onClick={() => setAdminTurnedOff(!adminTurnedOff)} variant="contained" color="primary" style={{ color: "#ffffff" }}>
              {adminTurnedOff ? "turn on admin" : "turn off admin"}
            </Button>
          </div>
        )}
        <div className="w-full flex my-4 justify-between items-center">
          <div className="flex items-center gap-2 relative">
            <h1 className="text-2xl m-0 p-0 inline-block">Deals</h1>
            <motion.button
              initial={{ scale: 1 }}
              whileHover={{ scale: 1, rotate: 90 }}
              whileTap={{ scale: 1 }}
              className="px-0.5 py-0.5 h-6 w-6 bg-primary-blue text-white flex justify-center rounded-full opacity-90 hover:bg-sky-600 transform text-xs "
              onClick={() => {
                setIsPreliminaryFormVisible(!isPreliminaryFormVisible);
              }}
            >
              <AddIcon fontSize="small"></AddIcon>
            </motion.button>
            <AnimatePresence>
              {isPreliminaryFormVisible && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className={"fixed h-screen w-screen top-0 left-0 flex items-center justify-center z-50"}
                >
                  <AddDealPreliminaryInfo
                    className="z-50"
                    isFormVisible={isPreliminaryFormVisible}
                    callback={() => setIsPreliminaryFormVisible(!isPreliminaryFormVisible)}
                  ></AddDealPreliminaryInfo>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {lastSyncInvoices && (
            <span className="text-xs text-gray-400">
              Last sync with exact: <Moment format=" D MMM YYYY, HH:mm">{lastSyncInvoices}</Moment>{" "}
            </span>
          )}
        </div>
        <div className={"w-full bg-white rounded-xl overflow-hidden"} ref={ref}>
          <FilterForm additionalStyle={isMobile && " hidden"} formFields={formFields} adminClaim={hasAdminView} />
          <FormGenerator
            customFormStyles={{
              parent: `h-full min-h-[60px] p-4 lg:pt-0 lg:min-w-[460px] overflow-auto border-box flex flex-wrap gap-y-2 gap-x-4 bg-white rounded-xl justify-start items-center ${
                isMobile ? " hidden" : ""
              }
              ${!dateIsChangedWithDateRangePicker ? "opacity-40" : "opacity-100"}`,
              children: "w-[180px]",
            }}
            formFields={formFieldsFromToDates}
            layoutOptions={{ hasLabelsOnTop: false, hasCloseButton: false }}
          />
        </div>
        <div className="relative w-full my-10 px-8 bg-white rounded-xl">
          {paymentPerformanceInEur && (
            <>
              <Barplot location="myDeals" data={paymentPerformanceInEur} loading={loading} />
            </>
          )}
        </div>
        <div className="relative">
          <div className="w-full">
            <NavigationButtons additionalStyle={isMobile && " justify-center"} navigateBack={navigateBack} navigateForward={navigateForward} />
            <div className="w-full p-4 bg-white md:p-8 rounded-xl">
              <AnalyticsBar
                onClick={() => history.push(`networkgraph?${queryString.stringify(newQueries)}`)}
                formFields={formFields}
                dealStatistics={dealStatistics && "dealstatistics" in dealStatistics ? dealStatistics.dealstatistics : undefined}
                paymentPerformanceInEur={paymentPerformanceInEur ?? undefined}
                tooltipExpandsOnTop={true}
              />
              <div className="w-full gap-10 grid-card-container !mt-2">
                {deals &&
                  deals.dealslist &&
                  deals.dealslist.length > 0 &&
                  deals.dealslist.map((deal) => {
                    return (
                      <motion.div key={deal.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
                        <DealCard cardKey="dealcard1" data={deal} hasAdminView={hasAdminView} />
                      </motion.div>
                    );
                  })}
              </div>
              <AnimatePresence>
                {deals && deals.dealslist && deals.dealslist.length < 1 && (
                  <motion.div
                    className="w-11/12 p-10 mx-auto mt-3 text-center bg-gray-100 rounded-xl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="block text-gray-500">
                      <h1 className="text-3xl"> No deals on this page</h1>
                    </div>
                    <div className="flex flex-wrap justify-center w-8/12 gap-4 mx-auto my-4">
                      <AnimatePresence>
                        {newQueries &&
                          Object.entries(newQueries).map(([key, value]) => {
                            if (value > 0) {
                              return (
                                <motion.div
                                  initial={{ opacity: 0, y: -10 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  exit={{ opacity: 0, y: -10 }}
                                  transition={{ duration: 0.4, delay: 0.5 }}
                                  key={`${key}_${value}`}
                                >
                                  <FilterLabels
                                    allData={
                                      key === "broker"
                                        ? allBrokers.brokers
                                        : key === "client"
                                        ? allClients.clients
                                        : key === "clientTwo"
                                        ? allClients.clients
                                        : key === "product"
                                        ? allProducts.products
                                        : key === "dealInvoiceStatus"
                                        ? dealInvoiceStatusOptions
                                        : []
                                    }
                                    setRemoveFilter={
                                      key === "broker"
                                        ? setBroker
                                        : key === "client"
                                        ? setClientAndClientTwo
                                        : key === "clientTwo"
                                        ? setClientTwo
                                        : key === "product"
                                        ? setProduct
                                        : key === "dealInvoiceStatus"
                                        ? setDealInvoiceStatus
                                        : setDelayOlyxRef
                                    }
                                    data={{ key: key, value: value }}
                                  ></FilterLabels>
                                </motion.div>
                              );
                            }
                          })}
                      </AnimatePresence>
                    </div>
                    <div className="block text-gray-400">
                      <p>Try some other filters or try navigating back </p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <NavigationButtons additionalStyle={!isMobile ? " hidden" : " mt-5 justify-center"} navigateBack={navigateBack} navigateForward={navigateForward} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyDeals;
