import { useState } from "react";
import AddBackdropModal from "../../components/Modals/AddBackdropModal";
import CreateOrUpdateProductForm from "../../components/CreateOrUpdateProductForm/CreateOrUpdateProductForm";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import { motion } from "framer-motion";

function AddProductModal() {
  const [showAddOrCreateProductModal, setShowAddOrCreateProductModal] = useState(false);

  const handleCloseForm = () => {
    setShowAddOrCreateProductModal(false);
  };
  return (
    <>
      <AddBackdropModal open={showAddOrCreateProductModal} handleClose={handleCloseForm} height="fit-content">
        <CreateOrUpdateProductForm
          closeAfterSubmit={() => {
            handleCloseForm();
          }}
        />
      </AddBackdropModal>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        className={
          "rounded ml-4 p-2 text-white underline-offset-4 decoration-1 opacity-90 hover:bg-[#ffffff14] text-sm ease-in-out duration-300 flex items-center gap-2"
        }
        onClick={() => setShowAddOrCreateProductModal(true)}
      >
        <LocalGasStationIcon />
        CREATE NEW PRODUCT
      </motion.button>
    </>
  );
}

export default AddProductModal;
