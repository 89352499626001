import React, { useEffect, useRef, useState } from "react";
import { DateInputProps } from "../../../interfaces/InputProps";
import { format, isAfter, isBefore } from "date-fns";

const DateInput = ({
  additionalStyles,
  initialValue,
  newDateCantBeBefore,
  newDateCantBeAfter,
  onChange,
  label,
  showDefaultValue,
  hasLabelsOnTop,
  customFormStyles,
  order,
  isRequired,
  dateIsNotAllowedMessage,
}: DateInputProps) => {
  const [dateInputValue, setDateInputValue] = useState<string | undefined>(undefined);
  const [inputHasBeenActive, setInputHasBeenActive] = useState<boolean>(false);
  let dateWithMonthName: string = "";

  let ref = useRef<HTMLInputElement>(null);

  const showDatePicker = () => {
    if (ref.current) {
      ref.current.focus();
      ref.current.showPicker();
    }
  };

  const inputIsValid = (valid: boolean) => {
    if (ref.current && !valid) {
      ref.current.setCustomValidity(dateIsNotAllowedMessage ?? "Invalid Field.");
    } else if (ref.current && valid) {
      ref.current.setCustomValidity("");
    }
  };

  if (initialValue !== undefined && initialValue !== "") {
    dateWithMonthName = format(new Date(initialValue), "d MMM yyyy");
  }

  useEffect(() => {
    setDateInputValue(dateWithMonthName);
  }, [dateWithMonthName]);

  useEffect(() => {
    if (ref.current && order === "order-first") {
      ref.current.focus();
    }
  }, [order]);

  const validateDate = (date: Date, beforeDate?: string, afterDate?: string): boolean => {
    const newDateCantBeBeforeDate = beforeDate ? new Date(beforeDate) : null;
    const newDateCantBeAfterDate = afterDate ? new Date(afterDate) : null;

    return !((newDateCantBeBeforeDate && isBefore(date, newDateCantBeBeforeDate)) || (newDateCantBeAfterDate && isAfter(date, newDateCantBeAfterDate)));
  };

  useEffect(() => {
    if (initialValue) {
      const initialDate = new Date(initialValue);
      inputIsValid(validateDate(initialDate, newDateCantBeBefore, newDateCantBeAfter));
    }
  }, [newDateCantBeAfter, newDateCantBeBefore]);

  return (
    <div
      id="rootDiv"
      className={`relative ${customFormStyles ? customFormStyles : "h-12 max-w-fit"} ${order ? order : ""} ${additionalStyles ? additionalStyles : ""}`}
    >
      <div
        className={"p-[4px] h-[2.7em] cursor-pointer flex flex-nowrap rounded-xl border border-solid border-gray-200 text-base items-center"}
        onClick={showDatePicker}
      >
        <div className="flex flex-row flex-nowrap items-center relative w-full h-full">
          <label
            className={`${
              hasLabelsOnTop ? "absolute " : "inherit "
            }bg-white left-1 bottom-[30px] whitespace-nowrap md:m-auto px-1 text-[#9aa6b2] max-md:text-sm md:text-base`}
            htmlFor={label}
          >
            {label}:
          </label>
          <span
            className={`absolute top-[6px] left-[10px] z-10 text-dark-gray-filters pointer-events-none
            ${hasLabelsOnTop ? "" : " left-auto right-3 "}
            ${showDefaultValue ? (inputHasBeenActive ? "text-opacity-100" : "text-opacity-50") : "text-opacity-100"}`}
          >
            {dateInputValue}
          </span>
          <div className="w-full h-full text-dark-gray-filters grow relative flex items-center flex-row flex-nowrap text-opacity-50">
            <input
              className={`w-full text-dark-gray-filters focus:outline-0 border-[1px] border-transparent
              focus:border-[1px] bg-white  invalid:border-red-200 invalid:bg-red-100  h-[100%] pl-2 pr-2 rounded-[6px]`}
              value={initialValue ? initialValue : ""}
              id={label}
              onChange={
                onChange
                  ? (e) => {
                      if (e.target.value) {
                        const newDate = new Date(e.target.value);
                        inputIsValid(validateDate(newDate, newDateCantBeBefore, newDateCantBeAfter));
                      }
                      onChange(e.target.value);
                    }
                  : undefined
              }
              onFocus={() => {
                setInputHasBeenActive(true);
              }}
              ref={ref}
              required={isRequired}
              type={"date"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateInput;
