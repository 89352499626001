import { useState, useEffect } from "react";
import PaymentPerformanceClientlist from "./PaymentPerformanceClientlist";

export const BarplotRects = ({ data, barHeight, xScale, isHovered, thisYear }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = (e) => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <>
      <div
        key={data.id}
        className={`inline-block ${data.type} ${
          data.label === "?"
            ? "question-mark"
            : data.label === thisYear && data.type === "thisyear"
            ? "paid"
            : data.label === "Overdue > 90 days"
            ? "Overdue90"
            : data.label === thisYear && data.type === "future"
            ? "thisyear thisyear-future"
            : data.label
        } ${
          data.group === "thisyearGroup" && isHovered.group === "thisyearGroup"
            ? "opacity-100 thisYearGroup"
            : hovered && data.type === isHovered.type
            ? "opacity-100"
            : Object.keys(isHovered).length < 1
            ? "opacity-100"
            : "opacity-40"
        } transition-all	duration-300 shape ${hovered && data.type === "future" && data.label !== thisYear ? "border-x-[0.2px] border-[#D9D9D9]" : " "} `}
        style={{ width: xScale(data.expectedRevenue) + "px", height: barHeight + "px" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      ></div>
    </>
  );
};

export default BarplotRects;
