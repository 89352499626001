import AutoCompleteDropdown from "../FilterComponents/AutoCompleteDropdown/AutoCompleteDropdown";
import Checkboxes from "../FilterComponents/Checkboxes/Checkboxes";
import ClearIcon from "@mui/icons-material/Clear";
import DateInput from "../FilterComponents/DateInput/DateInput";
import FormGeneratorProps, { FormField } from "../../interfaces/FormGeneratorProps";
import React, { KeyboardEventHandler } from "react";
import RegularInput from "../FilterComponents/RegularInput/RegularInput";
import NumberInput from "../FilterComponents/NumberInput/NumberInput";
import { motion } from "framer-motion";

function FormGenerator({ closeFormFunction, customFormStyles, formFields, layoutOptions, onSubmitFromParent }: FormGeneratorProps) {
  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <form
      onKeyDown={handleKeyDown}
      autoComplete="off"
      className="formGenerator bg-white h-full w-full rounded-xl relative"
      data-testid="formGenerator"
      onSubmit={onSubmitFromParent}
    >
      <div className={customFormStyles?.parent ? customFormStyles.parent : " flex flex-wrap mb-2 w-full gap-4 p-4 rounded-lg h-fit"}>
        {layoutOptions?.hasCloseButton && (
          <div className=" transform absolute top-[10px] right-[15px] box-shadow bg-white hover:bg-gray-200 rounded-[50%] z-10">
            <motion.button
              type="button"
              className="w-[32px] h-[32px]"
              initial={{ scale: 1 }}
              onClick={closeFormFunction}
              whileHover={{ scale: 1, rotate: 90 }}
              whileTap={{ scale: 1 }}
            >
              <ClearIcon className=" text-dark-gray-filters scale-60"></ClearIcon>
            </motion.button>
          </div>
        )}

        {formFields.map((value: FormField, idx) => {
          if (value.type === "dropdown") {
            return (
              <AutoCompleteDropdown
                customFormStyles={customFormStyles?.children}
                hasLabelsOnTop={layoutOptions?.hasLabelsOnTop}
                initialValue={value.initialValue ?? "0"}
                isRequired={value.isRequired}
                key={`${value.label}-${idx}`}
                label={value.label}
                onChange={value.onChange ?? (() => {})}
                options={value.options ?? []}
                order={value.order}
                placeholder={value.placeholder ?? "All"}
                additionalStyles={value.additionalStyles ?? ""}
              />
            );
          } else if (value.type === "checkboxes") {
            return (
              <Checkboxes
                checkboxInitialValue={value.checkboxInitialValue ?? []}
                checkboxOptions={value.checkboxOptions ?? []}
                key={`${value.label}+${idx}`}
                label={value.label}
                onChange={value.onChange ?? (() => {})}
                order={value.order}
                placeholder="Select an option"
                additionalStyles={value.additionalStyles ?? ""}
              />
            );
          } else if (value.type === "text") {
            return (
              <RegularInput
                customFormStyles={customFormStyles?.children}
                hasLabelsOnTop={layoutOptions?.hasLabelsOnTop}
                initialValue={value.initialValue}
                isRequired={value.isRequired}
                key={`${value.label}-${idx}`}
                label={value.label}
                onChange={value.onChange}
                order={value.order}
                showDefaultValue={value.showDefaultValue}
                type={value.type}
                additionalStyles={value.additionalStyles ?? ""}
              />
            );
          } else if (value.type === "number") {
            return (
              <NumberInput
                customFormStyles={customFormStyles?.children}
                hasLabelsOnTop={layoutOptions?.hasLabelsOnTop}
                initialValue={value.initialValue}
                isRequired={value.isRequired}
                key={`${value.label}-${idx}`}
                label={value.label}
                onChange={value.onChange}
                order={value.order}
                showDefaultValue={value.showDefaultValue}
                step={value.step}
                additionalStyles={value.additionalStyles ?? ""}
              />
            );
          } else if (value.type === "date") {
            return (
              <DateInput
                customFormStyles={customFormStyles?.children}
                hasLabelsOnTop={layoutOptions?.hasLabelsOnTop}
                initialValue={value.initialValue}
                isRequired={value.isRequired}
                key={`${value.label}-${idx}`}
                label={value.label}
                dateIsNotAllowedMessage={value.dateIsNotAllowedMessage}
                newDateCantBeBefore={value.newDateCantBeBefore}
                newDateCantBeAfter={value.newDateCantBeAfter}
                onChange={value.onChange}
                order={value.order}
                showDefaultValue={value.showDefaultValue}
                additionalStyles={value.additionalStyles ?? ""}
              />
            );
          } else if (value.type === "heading") {
            return (
              <h1 className={`${value.order} w-full text-[#303030] text-[20px]   pl-2 pb-0 pt-0 mt-[8px] mb-[4px]`} key={`${value.label}-${idx}`}>
                {value.label}{" "}
              </h1>
            );
          } else if (value.type === "submit") {
            return (
              <div className={`z-20 w-full ${value.order} flex flex-wrap justify-end absolute bottom-0 left-0 md:relative`} key={`${value.label}-${idx}`}>
                <div className="w-full self-end" id="rootDiv">
                  <input
                    className="w-full rounded-b-xl p-4 py-2 md:px-4 md:py-1 md:w-auto text-lg lg:text-md block ml-auto text-white bg-primary-blue md:rounded-xl cursor-pointer"
                    name="submit"
                    type="submit"
                    value={value.label}
                  />
                </div>
              </div>
            );
          } else {
            throw new Error("Wrong `FormField` type.");
          }
        })}
      </div>
    </form>
  );
}

export default FormGenerator;
