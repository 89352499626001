import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "typeface-roboto";
import "./styles/index.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { auth } from "./firebase.js";
import validate from "validate.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
// });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_LINK,
});

const queryClient = new QueryClient();

validate.validators.inclusionstring = function(value, options) {
  let result = false;
  options.within.forEach((option) => {
    if (value.toLowerCase().indexOf(option.toLowerCase()) > -1) {
      result = true;
    }
  });
  if (result) {
    return;
  } else {
    return options.message;
  }
};

const authLink = setContext(async (_, { headers }) => {
  const newHeaders = {
    headers: {
      ...headers,
      Authorization: await auth.currentUser.getIdToken(false), //token ? token : token,
      Accept: "application/json",
      // "Content-Type": "application/json"
    },
  };
  // console.log("HEADERS")
  // console.log(newHeaders)
  return newHeaders;
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // deals: offsetLimitPagination(),
          invoices: {
            merge(existing, incoming) {
              return incoming;
            },
            read(existing) {
              return existing;
            },
          },
          dealslist: {
            merge(existing, incoming) {
              return incoming;
            },
            read(existing) {
              return existing && existing.filter((el) => el !== null);
            },
          },
          checkinvoicesperdeal: {
            // ...offsetLimitPagination(),
            keyArgs: false,
            merge(existing, incoming) {
              if (!existing || existing === undefined) {
                return [...incoming];
              }
              return [...incoming];
              // return( [...existing, ...incoming] )
            },
            read(existing, { args, variables }) {
              // implementation of read
              if (existing && args.cursor && !variables.endCursor) {
                // if cursor provided, return first 10 elements from start of cursor
                const result = existing
                  .filter((el) => el !== null)
                  .filter((el) => parseInt(el.dealId) < args.cursor)
                  // .filter( (el) => variables.after ? el.deal.dealDate >= new Date( variables.after) : true )
                  .sort((a, b) => parseInt(b.dealId) - parseInt(a.dealId))
                  .slice(0, 10);
                return result;
              }
              if (existing && variables.endCursor) {
                const result = existing
                  .filter((el) => el !== null)
                  .filter((el) => parseInt(el.dealId) > variables.endCursor)
                  .sort((a, b) => parseInt(b.dealId) - parseInt(a.dealId))
                  .slice(-10);
                if (result.length >= 10) {
                  return result;
                }
                const resultSupllemented = existing.filter((el) => el !== null).slice(0, 10);
                return resultSupllemented;
              }

              // return existing && existing.slice(-10).filter( (el) => el !== null )
              return existing && existing.filter((el) => el !== null);
            },
          },
        },
      },
    },
  }),
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={client}>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <App />
      </PostHogProvider>
    </ApolloProvider>
  </QueryClientProvider>
);

serviceWorker.unregister();
