import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "#9BA3AE",
    height: "40px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E1E1E1",
      borderRadius: "10px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E1E1E1",
      borderRadius: "10px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E1E1E1",
      borderRadius: "10px",
    },
  },
  option: {
    backgroundColor: "white",
    color: "#171720",
    padding: "10px",
    margin: "1px !important",
  },
  "&.MuiAutocomplete-input": {
    backgroundColor: "red",
  },
  clearIndicator: {
    height: "29px",
    color: "#364E66",
  },
}));

function AutoCompletePeriod(props) {
  const inputWidth = 150 + props.value.label.length * 10;
  const classes = useStyles();

  return (
    <>
      <AutoComplete
        popupIcon={<KeyboardArrowDownIcon />}
        autoWidth={true}
        classes={classes}
        value={props.value}
        className={`${props.value.label === "All" ? "select date" : "selected"} ${
          props.isInactive ? "opacity-40" : "opacity-100"
        } selection w-full md:max-w-auto`}
        options={[...props.options]}
        getOptionLabel={(option) => `${option.label}`}
        onChange={props.onChange}
        style={{ width: `${inputWidth}px` }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.value.label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: <span className="placeholder mx-2 whitespace-nowrap">{props.name}:</span>,
            }}
            fullWidth
          />
        )}
      />
    </>
  );
}

export default AutoCompletePeriod;
