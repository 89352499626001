import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  eachYearOfInterval,
  addYears,
  compareDesc,
  addWeeks,
  addMonths,
  isValid,
  isSameDay,
} from "date-fns";
import format from "date-fns/format";

const helpersMyDeals = {};

helpersMyDeals.periods = [
  {
    label: "All",
    dateAfter: format(startOfYear(new Date(2014, 1, 2)), "yyyy-MM-dd"),
    dateBefore: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    label: "This week",
    dateAfter: format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
    dateBefore: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
  },
  {
    label: "Last week",
    dateAfter: format(startOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }), "yyyy-MM-dd"),
    dateBefore: format(endOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }), "yyyy-MM-dd"),
  },

  {
    label: "This month",
    dateAfter: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    dateBefore: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  },
  {
    label: "Last month",
    dateAfter: format(startOfMonth(addMonths(new Date(), -1)), "yyyy-MM-dd"),
    dateBefore: format(endOfMonth(addMonths(new Date(), -1)), "yyyy-MM-dd"),
  },
  {
    label: "This year",
    dateAfter: format(startOfYear(new Date()), "yyyy-MM-dd"),
    dateBefore: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  {
    label: "2020-Today",
    dateAfter: format(startOfYear(new Date(2020, 1, 2)), "yyyy-MM-dd"),
    dateBefore: format(endOfYear(new Date()), "yyyy-MM-dd"),
  },
  ...eachYearOfInterval({ start: new Date(2014, 1, 2), end: addYears(new Date(), -1) })
    .sort((a, b) => compareDesc(a, b))
    .map((year) => {
      const result = { label: format(year, "yyyy"), dateAfter: format(startOfYear(year), "yyyy-MM-dd"), dateBefore: format(endOfYear(year), "yyyy-MM-dd") };
      return result;
    }),
];

helpersMyDeals.findPeriodOption = (dateAfter, dateBefore, periods) => {
  if (!isValid(new Date(dateAfter)) || !isValid(new Date(dateBefore))) {
    return null;
  }
  const matchingPeriodOption = periods.find(
    (period) => isSameDay(new Date(dateAfter), new Date(period.dateAfter)) && isSameDay(new Date(dateBefore), new Date(period.dateBefore))
  );
  return matchingPeriodOption ?? periods.find((period) => period.label === "All");
};

helpersMyDeals.dealInvoiceStatusOptions = [
  { id: "700", value: "HASPAID", label: "Paid", adminOnly: true },
  { id: "100", value: "HASDUE0", label: "Due", adminOnly: true },
  { id: "104", value: "HASDUE4", label: "OverDue", adminOnly: false },
  { id: "800", value: "HASUNKNOWN", label: "? Unknown", adminOnly: false },
  { id: "801", value: "HASUNKNOWNBUTCANCELLED", label: "Unknown but cancelled", adminOnly: true },
  { id: "200", value: "HASOUTSTANDING", label: "Has Outstanding", adminOnly: true },
  { id: "101", value: "HASDUE1", label: "Has OverDue [0, 30]", adminOnly: true },
  { id: "102", value: "HASDUE2", label: "Has OverDue [30, 90]", adminOnly: true },
  { id: "103", value: "HASDUE3", label: "Has OverDue [90, inf]", adminOnly: true },
  { id: "300", value: "HASABOUTTOSENDEXACT", label: "About To Send EXACT", adminOnly: true },
  { id: "400", value: "HASEXPREVMININVDIFF", label: "More exp than inv", adminOnly: true },
  { id: "500", value: "HASNOINVOICEDATE", label: "Missing Invoice Date", adminOnly: true },
  { id: "600", value: "HASSHOULDHAVEBEENSENTEXACT", label: "Should Have Been Sent According To Exact", adminOnly: true },
  { id: "701", value: "HASSHOULDBESENTBUTISNOTSENT", label: "Should be sent but is not sent", adminOnly: true },
  { id: "702", value: "HASABOUTTOCREATE", label: "About to create", adminOnly: true },
  { id: "703", value: "HASABOUTTOINVOICE", label: "About To Invoice", adminOnly: false },
  { id: "710", value: "HASABOUTTOCREATEUNTILTHISYEAR", label: "About to create EOY", adminOnly: true },
  { id: "720", value: "HASSTILLINVOICEDTHISYEAR", label: "Future EOY", adminOnly: true },
];

export default helpersMyDeals;
