import { useState } from "react";
import AddBackdropModal from "../../components/Modals/AddBackdropModal";
import CreateOrUpdateClientForm from "../../components/CreateOrUpdateClientForm/CreateOrUpdateClientForm";
import { motion } from "framer-motion";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

function AddClientModal() {
  const [showAddOrCreateClientModal, setshowAddOrCreateClientModal] = useState(false);

  const handleCloseForm = () => {
    setshowAddOrCreateClientModal(false);
  };
  return (
    <>
      <AddBackdropModal open={showAddOrCreateClientModal} handleClose={handleCloseForm} height="fit-content">
        <CreateOrUpdateClientForm
          closeAfterSubmit={() => {
            handleCloseForm();
          }}
        />
      </AddBackdropModal>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        className={
          "rounded ml-4 p-2 text-white underline-offset-4 decoration-1 opacity-90 hover:bg-[#ffffff14] text-sm ease-in-out duration-300 flex items-center gap-2"
        }
        onClick={() => setshowAddOrCreateClientModal(true)}
      >
        <PersonAddIcon />
        CREATE NEW CLIENT
      </motion.button>
    </>
  );
}

export default AddClientModal;
