export const additionalProductSpecs: { [name: string]: string[] } = {
  biodiesel: ["CFPP -20", "CFPP -15", "CFPP -10", "CFPP -5", "CFPP -3", "CFPP 0", "CFPP +3", "CFPP +5", "CFPP +10"],
  biomethane: [
    "Biomethane with THG-Quote",
    "22",
    "22 Uncertified waste",
    "22 Certified waste",
    "23",
    "23 Uncertified waste",
    "23 Certified waste",
    "24",
    "24 Uncertified waste",
    "24 Certified waste",
    "25",
    "25 Uncertified waste",
    "25 Certified waste",
  ],
  cae: ["24", "25"],
  hee: ["24", "25"],
  oerver: ["2022", "2023", "2024", "2025"],
  rtfc: ["22 NC", "23 NC", "24 NC", "25 NC"],
  thg: ["22 C", "22 A", "22 O", "22 B", "23 C", "23 A", "23 O", "23 B", "24 C", "24 A", "24 O", "24 B", "25 C", "25 A", "25 O", "25 B"],
  uco: ["FFA 5", "FFA 3", "FFA 7"],
  uer: ["22 German", "23 German", "22 FQD", "23 FQD", "24 German", "25 German"],
};

export const productIdToProductSpecMap: { [name: string]: string[] } = {
  "2": additionalProductSpecs.biodiesel,
  "29": additionalProductSpecs.biodiesel,
  "32": additionalProductSpecs.biomethane,
  "78": additionalProductSpecs.biomethane,
  "79": additionalProductSpecs.biomethane,
  "87": additionalProductSpecs.hee,
  "77": additionalProductSpecs.oerver,
  "34": additionalProductSpecs.rtfc,
  "5": additionalProductSpecs.thg,
  "10": additionalProductSpecs.thg,
  "72": additionalProductSpecs.thg,
  "1": additionalProductSpecs.uco,
  "36": additionalProductSpecs.uer,
  "73": additionalProductSpecs.uer,
  "90": additionalProductSpecs.cae,
};
